/*
  Project: Fresh film
  Author: Via Studios
 */

import lazySizes from 'lazysizes';
import initTimeline from './modules/timeline';
import initComponents from './modules/components';
import initInfScroll from './modules/infinitescroll';
import initMasonry from './modules/masonry';
import scrollCheck from './modules/scrollcheck';
import './modules/clips';

// Open/close main menu
const mainMenu = document.querySelector('.c-nav.c-nav--main');

// Lazyload image and video assets
lazySizes.init();

// Debounced scroll event listener to check for scroll direction and hide scrollCheckItems
scrollCheck();

// Open/close main menu
mainMenu.addEventListener('click', () => {
  mainMenu.classList.toggle('is-active');
});

// Init timeline, components, masonry, and infinite scroll
window.addEventListener('load', () => {
  initTimeline();
  initComponents();

  // Init masonry
  const msnry = initMasonry();
  const lazyImages = document.querySelectorAll('.lazyload');

  // Add event listener for the lazyloaded event
  lazyImages.forEach((img) => {
    img.addEventListener('lazyloaded', () => {
      msnry.layout();
    });
  });

  // Init infinite scroll effects news
  initInfScroll(msnry);
});
